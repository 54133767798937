import React, { useState } from "react";
import Sidebar from "../../common/components/Sidebar/sidebar";

import FormHeader from "../../common/components/FormHeader/FormHeader";
import DataForm from "../../common/components/dataEntryFormComponent/DataForm";
import Strings from "../../common/res/String";
import { useLocation } from "react-router-dom";
import RightSidebar from "../../common/components/RightSidebar/RightSidebar";
import TimeLine from "../../common/components/TimeLine/TimeLine";
import DataEntryEditForm from "../../common/components/dataEntryFormComponent/DataEntryEditForm";
import FormPage from "../../common/components/dataEntryFormComponent/FormPage";
export default function DataEntryForm() {
  const { state } = useLocation();
  const [isCount, setIsCount] = useState(1);
  console.log("KKSSS_LLSS", state);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2 p-0 col-md-3">
            <Sidebar />
          </div>
          {/* {state !== null ? (
            <>
              <div className="col-sm-10 col-lg-10">
                <div className="row align-items-center">
                  <div className="headerTimeLine px-5">
                    <TimeLine currentStep={isCount} />
                  </div>
                </div>
                <div className="row" style={{ paddingLeft: "3rem" }}>
                  <div
                    className="col-lg-9"
                    style={{
                      paddingTop: "4px",
                      // height: "calc(100vh - 20px)",
                      // overflow: "scroll",
                      // paddingBottom: "3rem",
                    }}
                  >
                    <div className="dataEntryMainContainer --copydocument">
                      <FormHeader header={Strings.data_entry} />
                      <div className="data-entry-form-block">
                        <DataEntryEditForm />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 p-0 right_col">
                    <div className="headerTimeLine_left">
                      <RightSidebar
                        setIsCount={setIsCount}
                        islabel="COPY DOCUMENT"
                      />
                   </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="col-sm-10 col-lg-8 mt-3 mb-4 offset-1 d-flex align-items-center p-0">
                <div className="dataEntryMainContainer">
                  <FormHeader header={Strings.data_entry} />
                  <div className="data-entry-form-block">
                    <DataForm />
                  </div>
                </div>
              </div>
            </>
          )} */}
          {state !== null ? (
            <>
              <div className="col-sm-10 col-lg-10">
                <div className="row align-items-center">
                  <div className="headerTimeLine px-5">
                    <TimeLine currentStep={isCount} />
                  </div>
                </div>
                <div className="row" style={{ paddingLeft: "3rem" }}>
                  <div
                    className="col-lg-9"
                    style={{
                      paddingTop: "4px",
                      // height: "calc(100vh - 20px)",
                      // overflow: "scroll",
                      // paddingBottom: "3rem",
                    }}
                  >
                    <div className="dataEntryMainContainer --copydocument">
                      <FormHeader header={Strings.data_entry} />
                      <div className="data-entry-form-block">
                        <DataForm />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 p-0 right_col">
                    <div className="headerTimeLine_left">
                      <RightSidebar
                        setIsCount={setIsCount}
                        islabel="COPY DOCUMENT"
                      />
                   </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
            <div className="col-sm-10 col-lg-8 mt-3 mb-4 offset-1 d-flex align-items-center p-0">
                <div className="dataEntryMainContainer">
                  <FormHeader header={Strings.data_entry} />
                  <div className="data-entry-form-block">
                    <DataForm />
                  </div>
                </div>
              </div>
            </>
          )} 
         
        </div>
      </div>
    </>
  );
}
