const Strings = {
  forgot_password: "Forgot password?",
  new_user: "New User",
  sign_in: "Sign In",
  welcome_back: "Welcome Back",
  // SideBarLabel
  data_management: "Data Management",
  data_entry: "Data Entry",
  user_management: "User Management",
  logout: "Logout",
  billnumber: "BL NO :",
  address: "ADDRESS :",
  exporter: "EXPORTER :",
  invoiceno: "INVOICE NO :",
  invoicedt: "INVOICE DT :",
  invoicequantity: "INVOICE QUANTITY :",
  invoicerate: "INVOICE RATE :",
  sblcinterest_charges: "SBLC INTEREST&CHARGES :",
  sblcinterestusdrate: "COST USD RATE :",
  bgamt_interestamt: "INVOICE AMOUNT :",
  bgnumberdate: "BG NUMBER & DATE :",
  foreignbankname: "FOREIGN BANK NAME :",
  bgcharges: "BG CHARGES :",
  upfrontcharges: "UPFRONT CHARGES :",
  advice: "ADVICE :",
  advicegst: "ADVICE GST :",
  margin10: "MARGIN 10% :",
  m1accountnumber: "M1 ACCOUNT NUMBER :",
  margin5: "MARGIN 5% :",
  m2accountnumber: "M2 ACCOUNT NUMBER :",
  beno: "BE NO :",
  bedate: "BE DATE :",
  beusd: "BE USD :",
  customduty: "CUSTOMS DUTY :",
  socialwelfarecharge: "SOCIAL WELFARE CHARGE :",
  begst: "BE IGST :",
  assessvalue: "ASSESS VALUE :",
  shippingbill: "SHIPPING BILL :",
  pqweight: "PQ WEIGHT:",
  pqfumigation: "PQ RELAX (1500) :",
  fumigationcharge: "FUMIGATION CHARGES(2500):",
  containersno: "CONTAINER NOS :",
  duedate: "DUE DATE :",
  bankdeductiondate: "BANK DEDUCTION DATE :",
  commodify: "COMMODIFY :",
  exchangerate: "EXCHANGE RATE :",
  dateofshipping: "DATE OF SHIPPING :",
  forwardcontractdate: "FORWARD CONTRACT DATE :",
  forwardcontractamount: "FORWARD CONTRACT AMOUNT :",
  etadate: "ETA DATE :",
  cfscharges: "CFS CHARGES",
  chasurvey: "CHA & SURVEY",
  freightcharge: "FREIGHT CHARGE:",
  unloading: "UNLOADING:",
  extraexpences: "EXTRA EXPENCES :",
  totalcft: "TOTAL CFT :",
  totalint: "TOTAL INT :",
  indirect_expenses: "INDIRECT EXPENSES :",
  od: "OD :",
  agents: "AGENTS (30) :",
  yardrent: "YARD RENT (3000) :",
  cfsname: "CFS NAME :",
  total_indirect_expenses: "TOTAL INDIRECT EXPENSES :",
  duty_payment_date: "DUTY PAYMENT DATE :",
  cost: "COST :",
  containerno: "CONTAINER NO :",
  avglength: "AVG LENGTH :",
  avgcft_avggirth: "AVG CFT/AVG GIRTH :",
  pcs: "PCS :",
  grosscbm: "GROSS CBM :",
  netcbm: "NET CBM :",
  rate: "RATE :",
  amt: "AMT :",
  lorry: "LORRY :",
  sellrate: "SELL RATE :",
  sellcbm: "SELL CBM :",
  brueshcomm: "BRIJESH COMM :",
  agent: "AGENT :",
  pkl: "PKL :",
  totalpcs: "TOTAL PCS :",
  totalgrosscbm: "TOTAL GROSS CBM :",
  totalnetcbm: "TOTAL NET CBM :",
  avgrate: "AVG RATE :",
  totalamt: "TOTAL AMOUNT :",
  roundoff: "ROUND OFF :",
  totalinvoiceamt: "TOTAL INVOICE AMOUNT :",
  bruesh: "BRIJESH :",
  cfsname: "CFS NAME :",
  dutypaydate: "DUTY PAYMENT DATE :",
  adduser: "ADD USER",
  userid: "USER ID :",
  firstname: "FIRST NAME :",
  lastname: "LAST NAME :",
  mobilenumber: "MOBILE NUMBER :",
  emailid: "EMAIL ID :",
  email_address: "Email address",
  password: "PASSWORD :",
  confirmpassword: "CONFIRM PASSWORD :",
  viewuser: "VIEW USER",
  edit: "EDIT",
  delete: "DELETE",
  edituser: "EDIT USER",
  agentManagement: "Agent Management",
  parkingListManagement: "PACKING LIST DETAILS",
  newParkingList: "New Packing List",
  fieldId: "FILE ID :",
  date: "DATE :",
  agentName: "AGENT NAME :",
  partyName: "PARTY NAME :",
  pkl: "PKL :",
  cmb: "CBM :",
  comm: "COMM :",
  load: "LOAD :",
  received: "RECEIVED :",
  paidAmount: "PAID AMOUNT :",
  BalanceAmount: "BALANCE AMOUNT :",
  gst: "GST :",
  total: "Total :",
  TDS: "TDS-2% :",
  examinationCharge: "EXAMINATION CHARGES :",
  cfsGroundRent: "CFS GROUND RENT(1500) :",
  sec49: "SEC49 (5000):",
  totalIndirectExpenses: "TOTAL INDIRECT EXPENSES :",
  forwardcover:'Forward Cover',
  fileName: 'FILE NAME :',
};
export default Strings;
