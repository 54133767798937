import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import InputField from "../inputField/InputField";
import Strings from "../../res/String";
import addIcon from "../../assets/img/addIcon.svg";
import deleteIcon from "../../assets/img/delete.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import PopUpModal from "../popupmodal/PopUpModal";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import logo from "../../assets/img/timber_logo.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import CloseIcon from "@mui/icons-material/Close";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Button, IconButton } from "@mui/material";
import FileUploadSection from "./FileUploadSection";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import FileUploadContainer from "./FileUploadContainer";
import * as XLSX from "xlsx";
import { useLocation, useNavigate } from "react-router-dom";
const DataEntryEditForm = () => {
  // API Start
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log("STATE", state);
  //

  // API END
  const [base64String, setBase64String] = useState([]);
  const [base64Xlsx, setBase64Xlsx] = useState("");
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dataEntryForm: state?.userForm || [],
    },
  });

  const { append, remove, fields } = useFieldArray({
    name: "dataEntryForm",
    control,
  });
  const [imageName, setImageName] = useState({});
  const excelInputRef = useRef(null);
  // const [state, setState] = React.useState({
  //   right: false,
  // });
  const [drawerOpen, setDrawerOpen] = useState(false);
  // Toggle drawer open/close

  const imageRef = useRef(null);

  const dataEntryForm = watch("dataEntryForm", []);
  const {
    roundOff,
    totalInt,
    extraExpenses,
    unLoading,
    freightCharge,
    chaSurvey,
    containersNumber,
    cfsCharges,
    fumigationCharge,
    pqFumigation,
    pqWeight,
    shippingBill,
    customDuty,
    socialWelfareCharge,
    bgCharges,
    margin10,
    margin5,
    invoiceQuantity,
    upFrontCharges,
    adviceGst,
    advice,
    sblcInterestCharges,
    sblcInterestUsdRate,
    totalCft,
    cost,
    od,
    yardRent,
    agents,
    cfschangesing,cfsChargesNew,chaSurveyNew
  } = watch();

  useEffect(() => {
    // BG-CHARGES
    const bggst = (Number(bgCharges) * 18) / 100;
    const bgInvs = Number(bgCharges) + Number(bggst);
    const bgval = (bgInvs * 6) / 100 / Number(invoiceQuantity) / 35.315;
    // UPFRONTCHARGES
    const frongst = (Number(upFrontCharges) * 18) / 100;
    const fronInvs = Number(upFrontCharges) + Number(frongst);
    const fronval = (fronInvs * 6) / 100 / Number(invoiceQuantity) / 35.315;
    // ADVICE
    const advisinst = Number(advice) + Number(adviceGst);
    const advisval = (advisinst * 6) / 100 / Number(invoiceQuantity) / 35.315;
    // MARGIN 10
    const marginval = (margin10 * 2.5) / 100 / Number(invoiceQuantity) / 35.315;
    // MARGIN 5
    const marginvalfive =
      (margin5 * 2.5) / 100 / Number(invoiceQuantity) / 35.315;
    // customDuty
    const customDutyval =
      (customDuty * 6) / 100 / Number(invoiceQuantity) / 35.315;
    // socialWelfareCharge
    const socialWelfareChargeval =
      (Number(socialWelfareCharge) * 6) /
      100 /
      Number(invoiceQuantity) /
      35.315;
    // shippingBill
    const shippingBillgst = (Number(shippingBill) * 18) / 100;
    const shippingBillInvs = Number(shippingBill) + Number(shippingBillgst);
    const shippingBillval =
      (shippingBillInvs * 6) / 100 / Number(invoiceQuantity) / 35.315;
    // PQ WEIGHT
    const pqWeightInvs = (Number(pqWeight) - 1) * 200 + 3500;
    const pqWeightval =
      (pqWeightInvs * 6) / 100 / Number(invoiceQuantity) / 35.315;
    // PQ FUMIGATION
    const pqFumigationInvs = pqFumigation * 0;
    const pqFumigationVal =
      (pqFumigationInvs * 6) / 100 / Number(invoiceQuantity) / 35.315;
    //FUMIGATION CHARGES
    const fumigationChargeGst = (Number(fumigationCharge) * 18) / 100;
    const fumigationChargeInvs =
      Number(fumigationCharge) + Number(fumigationChargeGst);
    const fumigationChargeVal =
      (Number(fumigationChargeInvs) * 6) /
      100 /
      Number(invoiceQuantity) /
      35.315;
    // CFS CHARGES
    const cfscharge = cfsChargesNew || 1 * Number(containersNumber);
    setValue("cfsChargesing", (cfscharge && cfscharge) || undefined);
    const cfsGst = (Number(cfsCharges) * 18) / 100;
    const cfsChargesInvs = Number(cfsCharges) + Number(cfsGst);
    const cfsChargesVal =
      (Number(cfsChargesInvs) * 6) / 100 / Number(invoiceQuantity) / 35.315;
    // CHA SURVEY
    const chasurvey = chaSurveyNew * Number(containersNumber);
    setValue("chaSurvey", (chasurvey && chasurvey) || undefined);
    const chaGst = (Number(chaSurvey) * 18) / 100;
    const chaSurveyInvs = Number(chaSurvey) + Number(chaGst);
    const chaSurveyVal =
      (Number(chaSurveyInvs) * 6) / 100 / Number(invoiceQuantity) / 35.315;

    // FREIGHTCHARGES
    const freightcharge = 7500 * Number(containersNumber);
    setValue("freightCharge", (freightcharge && freightcharge) || undefined);
    const freightChargeGst = (Number(freightCharge) * 5) / 100;
    const freightChargeInvs = Number(freightCharge) + Number(freightChargeGst);
    const freightChargeVal =
      (Number(freightChargeInvs) * 6) / 100 / Number(invoiceQuantity) / 35.315;
    //UNLOADING
    const unloading = 3000 * Number(containersNumber);
    setValue("unLoading", (unloading && unloading) || undefined);
    const unLoadingGst = (Number(unLoading) * 18) / 100;
    const unLoadingInv = Number(unLoading) + Number(unLoadingGst);
    const unLoadingVal =
      (Number(unLoadingInv) * 6) / 100 / Number(invoiceQuantity) / 35.315;

    // EXTRAEXPENSES
    setValue("extraExpenses", 24000);
    const extraExpensesGst = (Number(extraExpenses) * 18) / 100;
    const extraExpensesInvs = Number(extraExpenses) + extraExpensesGst;
    const extraExpensesVal =
      (Number(extraExpensesInvs) * 6) / 100 / Number(invoiceQuantity) / 35.315;
    // TOTAL INT
    const totalintval =
      Number(bgval) +
      Number(fronval) +
      Number(advisval) +
      Number(marginval) +
      Number(marginvalfive) +
      Number(customDutyval) +
      Number(socialWelfareChargeval) +
      Number(shippingBillval) +
      Number(pqWeightval) +
      Number(pqFumigationVal) +
      Number(fumigationChargeVal) +
      Number(cfsChargesVal) +
      Number(chaSurveyVal) +
      Number(freightChargeVal) +
      Number(unLoadingVal) +
      Number(extraExpensesVal);
    setValue("totalInt", (totalintval && totalintval) || undefined);
    // SBLC INTEREST AND CHARGES
    const sblcInterestChargesVal =
      (Number(sblcInterestCharges) * Number(sblcInterestUsdRate)) /
      Number(invoiceQuantity) /
      35.315;
    // BGCHARGES CFT
    const bgChargesCft = Number(bgCharges) / Number(invoiceQuantity) / 35.315;
    // UPFRONTCHARGES CFT
    const upFrontChargesCft =
      Number(upFrontCharges) / Number(invoiceQuantity) / 35.315;
    // ADVICECFT
    const adviceCft = Number(advice) / Number(invoiceQuantity) / 35.315;
    // CUSTOMDUTYCFT
    const customDutyCft = Number(customDuty) / Number(invoiceQuantity) / 35.315;
    // SOCIALWELFARECHARGECFT
    const socialWelfareChargeCft =
      Number(socialWelfareCharge) / Number(invoiceQuantity) / 35.315;
    // SHIPPINGBILLCFT
    const shippingBillCft =
      Number(shippingBill) / Number(invoiceQuantity) / 35.315;
    // PQWEIGHTCFT
    const pqWeightCft = Number(pqWeightInvs) / Number(invoiceQuantity) / 35.315;
    // PQFUMIGATION
    const pqFumigationCft =
      Number(pqFumigationInvs) / Number(invoiceQuantity) / 35.315;
    // FUMIGATIONCHARGES
    const fumigationChargesCft =
      Number(fumigationChargeInvs) / Number(invoiceQuantity) / 35.315;
    // CFSCHARGESCFT
    const cfsChargesCft = Number(cfsCharges) / Number(invoiceQuantity) / 35.315;
    // CHA SURVEY
    const chaSurveyCft = Number(chaSurvey) / Number(invoiceQuantity) / 35.315;
    // FREIGHTCHARGECFT
    const freightChargeCft =
      Number(freightCharge) / Number(invoiceQuantity) / 35.315;
    // UNLOADINGCFT
    const unLoadingCft = Number(unLoading) / Number(invoiceQuantity) / 35.315;
    // EXTRAEXPENSES CFT
    const extraExpensesCft =
      Number(extraExpenses) / Number(invoiceQuantity) / 35.315;

    const totalcftval =
      Number(sblcInterestChargesVal) +
      Number(bgChargesCft) +
      Number(upFrontChargesCft) +
      Number(adviceCft) +
      Number(customDutyCft) +
      Number(socialWelfareChargeCft) +
      Number(shippingBillCft) +
      Number(pqWeightCft) +
      Number(pqFumigationCft) +
      Number(fumigationChargesCft) +
      Number(cfsChargesCft) +
      Number(chaSurveyCft) +
      Number(freightChargeCft) +
      Number(unLoadingCft) +
      Number(extraExpensesCft);
    setValue("totalCft", (totalcftval && totalcftval) || undefined);

    // OD
    setValue("od", (totalInt && totalInt) || undefined);
    // AGENTS
    setValue("agents", 30);
    // YARD RENT
    const yardRentVal =
      (Number(containersNumber) * 3000) / Number(invoiceQuantity) / 35.315;
    setValue("yardRent", (yardRentVal && yardRentVal) || undefined);
    // COST
    const costVal =
      Number(totalCft) + Number(od) + Number(agents) + Number(yardRent);
    setValue("cost", (costVal && costVal) || undefined);
  }, [cfsChargesNew,chaSurveyNew,
    sblcInterestCharges,
    sblcInterestUsdRate,
    totalInt,
    extraExpenses,
    unLoading,
    freightCharge,
    chaSurvey,
    containersNumber,
    cfsCharges,
    fumigationCharge,
    pqFumigation,
    pqWeight,
    shippingBill,
    socialWelfareCharge,
    customDuty,
    bgCharges,
    margin5,
    margin10,
    invoiceQuantity,
    upFrontCharges,
    adviceGst,
    advice,
    totalCft,
    cost,
    od,
    yardRent,
    agents,
    cfschangesing
  ],);
  const [fileList, setFileList] = React.useState({});
  const [drawerState, setDrawerState] = React.useState({});
  //

  const [isPopupOpen, setPopupOpen] = useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  const pdfToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  //
  const copydocs = sessionStorage.getItem("copydoc");
  const [isImag, setIsImag] = useState([]);
  const fetchcopydoc = async () => {
    return await axios.get(
      `${process.env.REACT_APP_HOST}/copydocuments/${state?.blNo}`
    );
  };
  const Copydoc = useQuery("FILEES", fetchcopydoc);
  useEffect(() => {
    sessionStorage.setItem(
      "copydoc",
      Copydoc?.data && Copydoc?.data?.data?.copydocuments
        ? Copydoc?.data && Copydoc?.data?.data?.copydocuments
        : []
    );

    setIsImag(copydocs);
  }, [Copydoc]);
  console.log("retrievedData_FILE", isImag);
  const handleFileChange = async (e, arrayName) => {
    const files = Array.from(e.target.files);
    const promises = Array.from(files).map((file) => pdfToBase64(file));

    try {
      const base64Strings = await Promise.all(promises);
      setBase64String((prevArray) => [
        ...(prevArray && prevArray),
        ...base64Strings,
      ]);
    } catch (error) {
      console.error("Error converting PDF to Base64:", error);
    }
    //  setBase64String(base64Array);
    setFileList((prev) => ({
      ...prev,
      [arrayName]: [...(prev[arrayName] || []), ...files],
    }));
  };
  const HandlerDelete = async (i) => {
    try {
      // Ensure deleteIndex is used correctly
      const response = await axios.delete(
        `${process.env.REACT_APP_HOST}/copydocuments/${state?.blNo}/copy/${i}`
      );
      console.log("Delete response:", response.data);
      setIsImag([]);
      // Clear the deleteIndex
      return response.data;
    } catch (error) {
      console.log("Error", error);
    }
  };

  const removeFile = (i) => {
    console.log("KKKDDDD", i);

    HandlerDelete(i);
    // setFileList((prev) => ({
    //   ...prev,
    //   [arrayName]: prev[arrayName].filter((file) => file.name !== fileName),
    // }));
    // setBase64String((prevArray) =>
    //   prevArray.filter((_, index) => {
    //     const fileListForArray = fileList[arrayName];
    //     if (!fileListForArray) return true;
    //     const fileToRemove = fileListForArray.find(
    //       (file) => file.name === fileName
    //     );
    //     return index !== fileListForArray.indexOf(fileToRemove);
    //   })
    // );
  };

  const toggleDrawer = (arrayName, open) => () => {
    setDrawerState((prev) => ({ ...prev, [arrayName]: open }));
  };

  const formData = new FormData();

  fileList &&
    fileList?.copydoc &&
    fileList?.copydoc.forEach((value) => {
      formData.append("copydocument[]", value);
    });

  const [files, setFiles] = useState(Array(10).fill(null));
  const [base64Files, setBase64Files] = useState(Array(10).fill(null));
  const handleFileChanges = (index, event) => {
    const file = event.target.files[0];
    console.log("KK<<<<>>>>>", file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Getting base64 string without metadata
        console.log("Base64 String: ", base64String);

        setFiles((prevFiles) => {
          const newFiles = [...prevFiles];
          newFiles[index] = file;
          return newFiles;
        });

        setBase64Files((prevBase64Files) => {
          const newBase64Files = [...prevBase64Files];
          newBase64Files[index] = base64String;
          return newBase64Files;
        });
      };

      reader.readAsDataURL(file);
    }

    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = file;
      return newFiles;
    });
  };

  const handleFileRemove = (index) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = null;
      return newFiles;
    });
    setBase64Files((prevBase64Files) => {
      const newBase64Files = [...prevBase64Files];
      newBase64Files[index] = null;
      return newBase64Files;
    });
  };

  const updateTotals = () => {
    const totalPcs = dataEntryForm?.reduce((total, item) => {
      const pcs = parseFloat(item?.pcs);
      return total + (!isNaN(pcs) ? pcs : 0);
    }, 0);
    const totalAmt = dataEntryForm?.reduce((total, item) => {
      const amt = parseFloat(item?.amt);
      return total + (!isNaN(amt) ? amt : 0);
    }, 0);
    const totalgrossCbm = dataEntryForm?.reduce((total, item) => {
      const grossCbm = parseFloat(item?.grossCbm);
      return total + (!isNaN(grossCbm) ? grossCbm : 0);
    }, 0);
    const totalnetCbm = dataEntryForm?.reduce((total, item) => {
      const netCbm = parseFloat(item?.netCbm);
      return total + (!isNaN(netCbm) ? netCbm : 0);
    }, 0);
    const totalbrijesh = dataEntryForm?.reduce((total, item) => {
      const brijeshcomm = parseFloat(item?.brijeshComm);
      return total + (!isNaN(brijeshcomm) ? brijeshcomm : 0);
    }, 0);
    setValue("brijesh", totalbrijesh);
    setValue("totalNetCbm", totalnetCbm);
    setValue("totalGrossCbm", totalgrossCbm);
    setValue("totalPcs", totalPcs);
    setValue("totalAmount", totalAmt);
    let totalavgrateval = Number(totalAmt) / Number(totalnetCbm);
    setValue("totalAvgRate", (totalavgrateval && totalavgrateval) || undefined);
    let totalinvoice = Number(totalAmt) - Number(roundOff);
    setValue("totalInvoiceAmt", (totalinvoice && totalinvoice) || undefined);
  };
  useEffect(() => {
    updateTotals();
  }, [dataEntryForm, roundOff]);

  const [drawerOpenIndex, setDrawerOpenIndex] = useState(null);
  // containerImage
  // State to store Base64 strings and file names
  const [fileBase64, setFileBase64] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const imageRefs = useRef([]);
  console.log("fileNames", fileNames);
  // Handle file input changes
  const handleImageChange = async (index, event) => {
    const files = Array.from(event.target.files);
    console.log("files123", files);

    try {
      // Convert files to Base64 strings
      const base64Strings = await convertFilesToBase64(files);
      updateLocalState(index, files, base64Strings);
      setValue(`dataEntryForm.${index}.containerImage`, files);
    } catch (error) {
      console.error("Error converting files to Base64:", error);
    }
  };

  // Convert files to Base64 strings
  const convertFilesToBase64 = (files) => {
    return Promise.all(
      files.map(
        (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = () => reject("Error reading file");
            reader.readAsDataURL(file);
          })
      )
    );
  };

  // Update local state for Base64 strings and file names
  const updateLocalState = (index, files, base64Strings) => {
    setFileBase64((prevState) => {
      const updatedFileBase64 = [...prevState];
      updatedFileBase64[index] = base64Strings;
      return updatedFileBase64;
    });

    setFileNames((prevState) => {
      const updatedFileNames = [...prevState];
      updatedFileNames[index] = files.map((file) => file.name);
      return updatedFileNames;
    });
  };

  // Remove a specific file from the list
  const handleRemoveFile = (formIndex, fileIndex) => {
    setFileBase64((prevState) => {
      const updatedFileBase64 = [...prevState];
      updatedFileBase64[formIndex] = updatedFileBase64[formIndex].filter(
        (_, i) => i !== fileIndex
      );
      return updatedFileBase64;
    });

    setFileNames((prevState) => {
      const updatedFileNames = [...prevState];
      updatedFileNames[formIndex] = updatedFileNames[formIndex].filter(
        (_, i) => i !== fileIndex
      );
      return updatedFileNames;
    });
  };

  // Remove a field and its associated files
  const handleRemoveField = (index) => {
    remove(index);
    setFileBase64((prevState) => prevState.filter((_, i) => i !== index));
    setFileNames((prevState) => prevState.filter((_, i) => i !== index));
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const triggerExcelInput = () => {
    if (excelInputRef.current) {
      excelInputRef.current.click();
    }
  };
  // Handle file input change Excle UPLOAD
  const handleExcelFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });

        // Convert workbook to Base64 using XLSX built-in function
        const base64String = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "base64",
        });

        setBase64Xlsx(base64String);
      };
      reader.readAsBinaryString(file);
      setSelectedFile(file);
      setValue("summaryDoc", file); // Update react-hook-form value
    }
  };
  // Remove the selected file
  const removeExcelFile = () => {
    setSelectedFile(null);
    setBase64Xlsx("");
    setValue("summaryDoc", null); // Clear react-hook-form value
    excelInputRef.current.value = ""; // Clear file input value
  };
  console.log("base64Files", state,state?.cfsCharges );
  // console.log("stateEditValue", state);

  useEffect(() => {
    setValue("billno", state?.blNo);
    setValue("exporter", state?.exporter);
    setValue("cfsChargesNew",state?.cfsChargesNew)
    setValue("chaSurveyNew",state?.chaSurveyNew)
    setValue("address", state?.address);
    setValue("invoiceno", state?.invoiceNo);
    setValue("invoicedt", state?.invoiceDt);
    setValue("invoiceQuantity", state?.invoiceQuantity);
    setValue("invoiceRate", state?.invoiceRate);
    setValue("sblcInterestCharges", state?.sblcInterestAndCharges);
    setValue("sblcInterestUsdRate", state?.sblcInterestUsdRate);
    setValue("bgAmtInterestAmt", state?.bgAmtAndInterestAmt);
    setValue("bgNumber", state?.bgNumber);
    setValue("bgDate", state?.bgdate);
    setValue("foreignBankName", state?.foreignBankName);
    setValue("bgCharges", state?.bgCharges);
    setValue("upFrontCharges", state?.upfrontCharges);
    setValue("advice", state?.advice);
    setValue("margin10", state?.margin10);
    setValue("adviceGst", state?.adviceGst);
    setValue("margin5", state?.margin5);
    setValue("M1AccountNumber", state?.m1AccountNumber);
    setValue("M2AccountNumber", state?.m2AccountNumber);
    setValue("beNo", state?.beNo);
    setValue("beDate", state?.beDate);
    setValue("beUsd", state?.beUsed);
    setValue("customDuty", state?.customsDuty);
    setValue("socialWelfareCharge", state?.socialWelfareCharges);
    setValue("beIgst", state?.beIgst);
    setValue("assessValue", state?.assessValue);
    setValue("shippingBill", state?.shippingBill);
    setValue("pqWeight", state?.pqWeight);
    setValue("pqFumigation", state?.pqFumigation);
    setValue("fumigationCharge", state?.fumigationCharges);
    setValue("containersNumber", state?.containersNumber);
    setValue("dueDate", state?.dueDate);
    setValue("bankDeductionDate", state?.bankDeductionDate);
    setValue("commodify", state?.commodify);
    setValue("exchangeRate", state?.exchangeRate);
    setValue("dateOfShipping", state?.dateOfShipping);
    setValue("forwardContractDate", state?.forwardContractDate);
    setValue("etaDate", state?.etaDate);
    setValue("fileName", state?.fileName);
    setValue("cfsChargesing", state?.cfsCharges);
    setValue("chaSurvey", state?.chaSurvey);
    setValue("freightCharge", state?.freightCharge);
    setValue("unLoading", state?.unloading);
    setValue("extraExpenses", state?.extraExpenses);
    setValue("totalCft", state?.totalCft);
    setValue("totalInt", state?.totalInt);
    setValue("od", state?.od);
    setValue("sec49", state?.sec49);
    setValue("cfsGroundRent", state?.cfsGroundRent);
    setValue("examinationCharge", state?.examinationCharge);
    setValue("agents", state?.agents);
    setValue("yardRent", state?.yardRent);
    setValue("cfsName", state?.cfsName);
    setValue("totalIndirectExpenses", state?.totalIndirectExpenses);
    setValue("dutyPaymentDate", state?.dutyPaymentDate);
    setValue("cost", state?.cost);

    if (state?.userForm) {
      console.log("USERFORM", state?.userForm);
      const newFieldsCount = state?.userForm.length;
      const currentFieldsCount = fields?.length;
      if (newFieldsCount > currentFieldsCount) {
        for (let i = currentFieldsCount; i < newFieldsCount; i++) {
          append({
            agent: state.userForm[i]?.agent || "",
            amt: state.userForm[i]?.amt || "",
            averageLength: state.userForm[i]?.averageLength || "",
            avgCftAvgGirth: state.userForm[i]?.avgCftAvgGirth || "",
            containerNo: state.userForm[i]?.containerNo || "",
            pcs: state.userForm[i]?.pcs || "",
            grossCbm: state.userForm[i]?.grossCbm || "",
            netCbm: state.userForm[i]?.netCbm || "",
            avgRate: state.userForm[i]?.avgRate || "",
            lorry: state.userForm[i]?.lorry || "",
            sellRate: state.userForm[i]?.sellRate || "",
            sellCbm: state.userForm[i]?.sellCbm || "",
            brijeshComm: state.userForm[i]?.brijeshComm || "",
            pkl: state.userForm[i]?.pkl || "",
            // containerImage: [...state?.userForm[i].containerImage],
          });
        }
      }
    }
    setValue("summaryDoc", state?.summaryDocument);
    setValue("totalPcs", state?.totalPcs);
    setValue("totalGrossCbm", state?.totalGrossCbm);
    setValue("totalNetCbm", state?.totalNetCbm);
    setValue("totalAvgRate", state?.totalAvgRate);
    setValue("totalAmount", state?.totalAmount);
    setValue("roundOff", state?.roundOff);
    setValue("totalInvoiceAmt", state?.totalInvoiceAmount);
    setValue("brijesh", state?.bruesh);
    // setValue("summaryDoc", selectedFile?.name);
  }, [setValue, state?.userForm, append, fields?.length]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postdata) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/invoices/${
          state && state?.id
        }`,
        postdata
      ),
    {
      onSuccess: (val) => {
        console.log("data updated successfully..");
        setPopupOpen(!isPopupOpen);
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  //  const useForm = watch()
  console.log("useForm_base64Xlsx", base64Xlsx);

  const onSubmit = (data) => {
    let formData;
    if (state && state?.id != null) {
      formData = {
        blNo: data && data?.billno,
        exporter: data && data?.exporter,
        address: data && data?.address,
        invoiceNo: data && data?.invoiceno,
        invoiceDt: data && data?.invoicedt,
        invoiceQuantity: Number(data && data?.invoiceQuantity),
        invoiceRate: Number(data && data?.invoiceRate),
        sblcInterestAndCharges: Number(data && data?.sblcInterestCharges),
        sblcInterestUsdRate: Number(data && data?.sblcInterestUsdRate),
        bgAmtAndInterestAmt: Number(data && data?.bgAmtInterestAmt),
        bgNumber: data && data?.bgNumber,
        bgdate: data && data?.bgDate,
        foreignBankName: data && data?.foreignBankName,
        bgCharges: Number(data && data?.bgCharges),
        upfrontCharges: Number(data && data?.upFrontCharges),
        advice: data && data?.advice,
        margin10: Number(data && data?.margin10),
        margin5: Number(data && data?.margin5),
        adviceGst: data && data?.adviceGst,
        m1AccountNumber: data && data?.M1AccountNumber,
        m2AccountNumber: data && data?.M2AccountNumber,
        beNo: data && data?.beNo,
        beDate: data && data?.beDate,
        beUsed: Number(data && data?.beUsd),
        customsDuty: Number(data && data?.customDuty),
        socialWelfareCharges: Number(data && data?.socialWelfareCharge),
        beIgst: Number(data && data?.beIgst),
        assessValue: Number(data && data?.assessValue),
        shippingBill: Number(data && data?.shippingBill),
        pqWeight: data && data?.pqWeight,
        pqFumigation: Number(data && data?.pqFumigation),
        fumigationCharges: data && data?.fumigationCharge,
        containersNumber: data && data?.containersNumber,
        dueDate: data && data?.dueDate,
        bankDeductionDate: data && data?.bankDeductionDate,
        commodify: data && data?.commodify,
        exchangeRate: Number(data && data?.exchangeRate),
        dateOfShipping: data && data?.dateOfShipping,
        forwardContractDate: data && data?.forwardContractDate,
        etaDate: data && data?.etaDate,
        fileName: data && data?.fileName,
        chaSurveyNew:Number(data && data?.chaSurveyNew),
        cfsCharges: Number(data && data?.cfsCharges),
        chaSurvey: Number(data && data?.chaSurvey),
        cfsGroundRent: data && data?.cfsGroundRent,
        examinationCharge: data && data?.examinationCharge,
        freightCharge: Number(data && data?.freightCharge),
        unloading: data && data?.unLoading,
        extraExpenses: data && data?.extraExpenses,
        sec49: data && data?.sec49,
        totalCft: data && data?.totalCft,
        totalInt: data && data?.totalInt,
        od: data && data?.od,
        agents: data && data?.agents,
        yardRent: data && data?.yardRent,
        cfsName: data && data?.cfsName,
        totalIndirectExpenses: data && data?.totalIndirectExpenses,
        dutyPaymentDate: data && data?.dutyPaymentDate,
        cost: data && data?.cost,
        userForm:
          data &&
          data?.dataEntryForm.map((entry) => ({
            containerNo: entry.containerNo,
            averageLength: entry.averageLength,
            avgCftAvgGirth: entry.avgCftAvgGirth,
            pcs: entry.pcs,
            grossCbm: entry.grossCbm,
            netCbm: entry.netCbm,
            avgRate: entry.avgRate,
            amt: entry.amt,
            lorry: entry.lorry,
            sellRate: entry.sellRate,
            sellCbm: entry.sellCbm,
            brijeshComm: entry.brijeshComm,
            agent: entry.agent,
            pkl: entry.pkl,
            containerImage: [],
          })),
        totalPcs: data && data?.totalPcs,
        totalGrossCbm: data && data?.totalGrossCbm,
        totalNetCbm: data && data?.totalNetCbm,
        totalAvgRate: Number(data && data?.totalAvgRate),
        totalAmount: Number(data && data?.totalAmount),
        roundOff: Number(data && data?.roundOff),
        totalInvoiceAmount: Number(data && data?.totalInvoiceAmt),
        bruesh: Number(data && data?.brijesh),
        copydocument: base64String,
        sblcDocument: base64Files[0],
        summarydocument:
          base64Xlsx !== "" ? base64Xlsx : state?.summaryDocument,
        invoicedocument: base64Files[1],
        phytodocument: base64Files[2],
        codocument: base64Files[3],
        bldocument: base64Files[4],
        quotedocument: base64Files[5],
        swiftintdocument: base64Files[6],
        advicedocument: base64Files[7],
        boedocument: base64Files[8],
        shippingbilldocument: base64Files[9],
      };
    }
    mutate(formData);
    console.log("formData", formData);
  };
  // const{dataEntryForm}=watch()
  const retrievedData = JSON.parse(sessionStorage.getItem("formdata"));

  console.log("stateEditValue_retrievedData", retrievedData);
  // useEffect(() => {
  //   const newFileNames = [];

   
  // }, [state?.userForm]);
  const [imageLengths, setImageLengths] = useState([]);
  useEffect(() => {
    // let lengths=0
    const lengths = state?.userForm.map(
      (item) => item&&item.containerImage&&item.containerImage?.length || 0
    );
    setImageLengths(lengths);
  }, [state?.userform]);
  console.log("imageLengths", imageLengths);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className=" px-3 py-4">
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.billnumber}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="billno"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="string"
                    placeholder="3421631"
                    step="any"
                    autofocus={true}
                    disable={true}
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.exporter}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="exporter"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="THIRU RANI LOGISTICS PVT LTD"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.address}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="address"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="ADDRESS"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.invoiceno}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="invoiceno"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="615"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.invoicedt}</label>
            <div className="data-entry-input">
              <Controller
                name="invoicedt"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    className="data-entry-date-field"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.invoicequantity}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="invoiceQuantity"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="85.550"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.invoicerate}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="invoiceRate"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="350"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.sblcinterest_charges}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="sblcInterestCharges"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="984.53"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.sblcinterestusdrate}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="sblcInterestUsdRate"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="84.04"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.bgamt_interestamt}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="bgAmtInterestAmt"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="USD 30,937.53"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.bgnumberdate}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="bgNumber"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="0042BG000232024"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <div className="data-entry-input">
              <Controller
                name="bgDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    className="data-entry-date-field"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.foreignbankname}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="foreignBankName"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="Foreign Bank Name"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.bgcharges}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="bgCharges"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="28638"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.upfrontcharges}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="upFrontCharges"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="5000"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.advice}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="advice"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="2199"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.margin10}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="margin10"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="25200"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.advicegst}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="adviceGst"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="2199"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.margin5}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="margin5"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="125200"
                    step="any"
                  />
                )}
              />
            </div>
          </div>

          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.m1accountnumber}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="M1AccountNumber"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="25846557851"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.m2accountnumber}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="M2AccountNumber"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="25846557851"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.beno}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="beNo"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="3421631"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.bedate}</label>
            <div className="data-entry-input">
              <Controller
                name="beDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    className="data-entry-date-field"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.beusd}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="beUsd"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="85.35"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.customduty}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="customDuty"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="127748.00"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.socialwelfarecharge}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="socialWelfareCharge"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="12774.80"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.begst}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="beIgst"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="485187.00"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.assessvalue}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="assessValue"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="2554959"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.shippingbill}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="shippingBill"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="141250"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.pqweight}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="pqWeight"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="139"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.pqfumigation}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="pqFumigation"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="1"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.fumigationcharge}
            </label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="fumigationCharge"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="0"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.containersno}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="containersNumber"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="5"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.duedate}</label>
            <div className="data-entry-input">
              <Controller
                name="dueDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    className="data-entry-date-field"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.bankdeductiondate}
            </label>
            <div className="data-entry-input">
              <Controller
                name="bankDeductionDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    className="data-entry-date-field"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.commodify}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="commodify"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="TEAK ROUND LOGS"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.exchangerate}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="exchangeRate"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="84.00"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.dateofshipping}</label>
            <div className="data-entry-input">
              <Controller
                name="dateOfShipping"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    className="data-entry-date-field"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">
              {Strings.forwardcontractdate}
            </label>
            <div className="data-entry-input">
              <Controller
                name="forwardContractDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    className="data-entry-date-field"
                  />
                )}
              />
            </div>
          </div>
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.etadate}</label>
            <div className="data-entry-input">
              <Controller
                name="etaDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    className="data-entry-date-field"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block">
            <label className="data-entry-label">{Strings.fileName}</label>
            <div className="data-entry-input">
              <Controller
                control={control}
                name="fileName"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="F1"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-3">
          <div className="data-entry-input-block-twos d-flex align-items-center">
          <div style={{width:"207px"}}>
            <label className="data-entry-label --data-entry-label-twos">
              {Strings.cfscharges}
            </label>
              <input placeholder="130000"{...register("cfsChargesNew")} style={{width:"130px",outline:"none",border:"1px solid #000", borderRadius:"9px",background:"transparent",fontSize:"12px",fontWeight:400,padding:"5px 10px"}}/> &nbsp;:&nbsp;
          </div>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="cfsChargesing"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="65000"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        {/* <div className="dataEntryInputContainer py-3">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.cfscharges}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="cfsCharges"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="65000"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div> */}
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-twos d-flex align-items-center">
          <div style={{width:"207px"}}>

            <label className="data-entry-label --data-entry-label-two">
              {Strings.chasurvey}
            </label>
            <input placeholder="6150"{...register("chaSurveyNew")} style={{width:"130px",outline:"none",border:"1px solid #000", borderRadius:"9px",background:"transparent",fontSize:"12px",fontWeight:400,padding:"5px 10px"}}/> &nbsp;:&nbsp;
          </div>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="chaSurvey"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="30750"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        {/* <div className="dataEntryInputContainer py-3">
          <div className="data-entry-input-block-twos d-flex align-items-center">
          <div style={{width:"207px"}}>
            <label className="data-entry-label --data-entry-label-twos">
              {Strings.cfscharges}
            </label>
              <input placeholder="130000"{...register("cfschangesing")} style={{width:"130px",outline:"none",border:"1px solid #000", borderRadius:"9px",background:"transparent",fontSize:"12px",fontWeight:400,padding:"5px 10px"}}/> &nbsp;:&nbsp;
          </div>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="cfsCharges"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="65000"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.chasurvey}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="chaSurvey"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="30750"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div> */}
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.examinationCharge}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="examinationCharge"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="8000"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.cfsGroundRent}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="cfsGroundRent"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="13"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.freightcharge}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="freightCharge"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="37500"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.unloading}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="unLoading"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="15000"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.extraexpences}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="extraExpenses"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="24000"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.sec49}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="sec49"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="13"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalcft}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="totalCft"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="109.75"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalint}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="totalInt"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="12.77"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="py-3">
          <h6 className="data-entry-sub-title">{Strings.indirect_expenses}</h6>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.od}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="od"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="12.77"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.agents}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="agents"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="30"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.yardrent}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="yardRent"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="4.96"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.cfsname}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="cfsName"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="text"
                    placeholder="THIRU RANI LOGISTICS PVT LTD"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalIndirectExpenses}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="totalIndirectExpenses"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="47.73"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.duty_payment_date}
            </label>
            <div className="data-entry-input-two">
              <Controller
                name="dutyPaymentDate"
                control={control}
                defaultValue={null}
                render={({ field }) => (
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd/MM/yyyy"
                    className="data-entry-date-field"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.cost}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="cost"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="247.48"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        {fields &&
          fields?.map((val, index) => {
            return (
              <>
                <div className="dataEntryAppendSection py-3" key={fields.id}>
                  <div className="data-entry-append-block">
                    <div className="dataEntryAppendInputContainer py-2">
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.containerno}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            control={control}
                            name={`dataEntryForm.${index}.containerNo`}
                            render={(field) => (
                              <InputField
                                dataEntryInputField="data-entry-append-input-field"
                                {...field}
                                type="text"
                                placeholder="CMAU3843174"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.avglength}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            control={control}
                            name={`dataEntryForm.${index}.averageLength`}
                            render={(field) => (
                              <InputField
                                dataEntryInputField="data-entry-append-input-field"
                                {...field}
                                type="number"
                                placeholder="2.27"
                                step="any"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dataEntryAppendInputContainer py-2">
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.avgcft_avggirth}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            control={control}
                            name={`dataEntryForm.${index}.avgCftAvgGirth`}
                            render={(field) => (
                              <InputField
                                dataEntryInputField="data-entry-append-input-field"
                                {...field}
                                type="number"
                                placeholder="5.43"
                                step="any"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.pcs}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            name={`dataEntryForm[${index}].pcs`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                placeholder="121"
                                type="number"
                                className="data-entry-append-input-field"
                                step="any"
                                onChange={(e) => {
                                  field.onChange(e);
                                  updateTotals();
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dataEntryAppendInputContainer py-2">
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.grosscbm}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            name={`dataEntryForm[${index}].grossCbm`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                placeholder="121"
                                type="number"
                                className="data-entry-append-input-field"
                                step="any"
                                onChange={(e) => {
                                  field.onChange(e);
                                  updateTotals();
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.netcbm}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            name={`dataEntryForm[${index}].netCbm`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                placeholder="17.160"
                                type="number"
                                className="data-entry-append-input-field"
                                step="any"
                                onChange={(e) => {
                                  field.onChange(e);
                                  updateTotals();
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dataEntryAppendInputContainer py-2">
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.rate}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            name={`dataEntryForm[${index}].avgRate`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                placeholder="17.160"
                                type="number"
                                className="data-entry-append-input-field"
                                step="any"
                                onChange={(e) => {
                                  field.onChange(e);
                                  updateTotals();
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.amt}
                        </label>

                        <div className="data-entry-input">
                          <Controller
                            name={`dataEntryForm[${index}].amt`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                placeholder="5.84"
                                type="number"
                                className="data-entry-append-input-field"
                                step="any"
                                onChange={(e) => {
                                  field.onChange(e);
                                  updateTotals();
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dataEntryAppendInputContainer py-2">
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.lorry}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            control={control}
                            name={`dataEntryForm.${index}.lorry`}
                            render={(field) => (
                              <InputField
                                dataEntryInputField="data-entry-append-input-field"
                                {...field}
                                type="text"
                                placeholder="TN 25 AQ 7388"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.sellrate}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            control={control}
                            name={`dataEntryForm.${index}.sellRate`}
                            render={(field) => (
                              <InputField
                                dataEntryInputField="data-entry-append-input-field"
                                {...field}
                                type="number"
                                placeholder="5.43"
                                step="any"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dataEntryAppendInputContainer py-2">
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.sellcbm}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            control={control}
                            name={`dataEntryForm.${index}.sellCbm`}
                            render={(field) => (
                              <InputField
                                dataEntryInputField="data-entry-append-input-field"
                                {...field}
                                type="number"
                                placeholder="7388"
                                step="any"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.brueshcomm}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            name={`dataEntryForm[${index}].brijeshComm`}
                            control={control}
                            render={({ field }) => (
                              <input
                                {...field}
                                placeholder="5.84"
                                type="number"
                                className="data-entry-append-input-field"
                                step="any"
                                onChange={(e) => {
                                  field.onChange(e);
                                  updateTotals();
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="dataEntryAppendInputContainer py-2">
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.agent}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            control={control}
                            name={`dataEntryForm.${index}.agent`}
                            render={(field) => (
                              <InputField
                                dataEntryInputField="data-entry-append-input-field"
                                {...field}
                                type="number"
                                placeholder="433"
                                step="any"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="data-entry-input-block">
                        <label className="data-entry-label --append-label">
                          {Strings.pkl}
                        </label>
                        <div className="data-entry-input">
                          <Controller
                            control={control}
                            name={`dataEntryForm.${index}.pkl`}
                            render={(field) => (
                              <InputField
                                dataEntryInputField="data-entry-append-input-field"
                                {...field}
                                type="number"
                                placeholder="5.43"
                                step="any"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <Controller
                        control={control}
                        name={`dataEntryForm.${index}.containerImage`}
                        render={({ field }) => (
                          <>
                            <input
                              type="file"
                              accept="image/*"
                              ref={(el) => (imageRefs.current[index] = el)}
                              style={{ display: "none" }}
                              multiple
                              onChange={(e) => {
                                handleImageChange(index, e);
                                field.onChange(e);
                              }}
                            />
                          </>
                        )}
                      />
                      <div
                        className="dataEntryAppendInputImage mx-3 my-2"
                        style={{ cursor: "not-allowed" }}
                        // onClick={() => imageRefs.current[index]?.click()}
                      >
                        <div className="dataEntryAppendInnerBlock">
                          <h5 className="data-entry-image-head">
                            ADD CONTAINER IMAGE
                          </h5>
                        </div>
                      </div>
                      <div style={{ marginTop: "10px" }}>
                        {/* {fileNames?.map((name, fileIndex) => {
                          return (
                            <>
                             
                              <div
                                key={fileIndex}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                          
                                {Array.from({ length: name }).map((_, i) => {
                                  console.log('====================================');
                                  console.log("FILES_MMMMMM<<<",name,fileNames);
                                  console.log('====================================');
                                  return(
                                  <div
                                    key={i}
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginRight: "10px",
                                    }}
                                  >
                                    <span>Container{i + 1}</span>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleRemoveFile(fileIndex, i)
                                      }
                                      style={{
                                        marginLeft: "5px",
                                        color: "red",
                                        border: "none",
                                        background: "transparent",
                                        cursor: "pointer",
                                      }}
                                    >
                                      &#x2716;
                                    </button>
                                  </div>
                                )})}
                              </div>
                            </>
                          );
                        })} */}
                        {imageLengths &&
                          imageLengths.map((e, i) => {
                            return (
                              <div
                                key={i}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                {state&&state?.userForm&&state?.userForm[i].containerImage&&state?.userForm[i].containerImage?.map(
                                  (e, f) => {
                                    if (index === i) {
                                      return (
                                        <div
                                          key={f}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginRight: "10px",
                                          }}
                                        >
                                          <p>ContainerImage{f > 0 ? f : ""}</p>
                                        </div>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="dataEntryRemoveInputContainer">
                    {index > 0 ? (
                      <div
                        className="imageIcon"
                        onClick={() => handleRemoveField(index)}
                      >
                        <img
                          src={deleteIcon}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </>
            );
          })}
        <div className="dataEntryAppendAddBtn my-4" onClick={() => append()}>
          <div className="dataEntryAppendInnerBlock">
            <div className="image_icon">
              <img src={addIcon} style={{ width: "100%", height: "100%" }} />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalpcs}
            </label>
            <div className="data-entry-input-two">
              <Controller
                name="totalPcs"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="data-entry-input-field"
                    placeholder="845"
                    readOnly
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalgrosscbm}
            </label>
            <div className="data-entry-input-two">
              <Controller
                name="totalGrossCbm"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="data-entry-input-field"
                    placeholder="93.600"
                    readOnly
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalnetcbm}
            </label>
            <div className="data-entry-input-two">
              <Controller
                name="totalNetCbm"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="data-entry-input-field"
                    placeholder="85.580"
                    readOnly
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.avgrate}
            </label>
            <div className="data-entry-input-two">
              <Controller
                name="totalAvgRate"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="data-entry-input-field"
                    placeholder="845"
                    readOnly
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalamt}
            </label>
            <div className="data-entry-input-two">
              <Controller
                name="totalAmount"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="data-entry-input-field"
                    readOnly
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.roundoff}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="roundOff"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="00.00"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.totalinvoiceamt}
            </label>
            <div className="data-entry-input-two">
              <Controller
                control={control}
                name="totalInvoiceAmt"
                render={(field) => (
                  <InputField
                    dataEntryInputField="data-entry-input-field"
                    {...field}
                    type="number"
                    placeholder="29953.45"
                    step="any"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="dataEntryInputContainer py-2">
          <div className="data-entry-input-block-two">
            <label className="data-entry-label --data-entry-label-two">
              {Strings.bruesh}
            </label>
            <div className="data-entry-input-two">
              <Controller
                name="brijesh"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    className="data-entry-input-field"
                    readOnly
                  />
                )}
              />
            </div>
          </div>
        </div>

        {["copydoc"].map((arrayName) => (
          <FileUploadSection
            key={arrayName}
            control={control}
            handleFileChange={handleFileChange}
            fileList={fileList}
            removeFile={removeFile}
            arrayName={arrayName}
            drawerState={drawerState}
            toggleDrawer={toggleDrawer}
            states={isImag}
          />
        ))}
        <Controller
          name="summaryDoc"
          control={control}
          defaultValue={null}
          render={({ field }) => (
            <>
              <input
                type="file"
                ref={excelInputRef}
                accept=".xls,.xlsx"
                onChange={(e) => {
                  handleExcelFileChange(e);
                  field.onChange(e); // Ensure react-hook-form is aware of the change
                }}
                style={{ display: "none" }} // Hide the file input
                multiple={false}
              />
            </>
          )}
        />
        <div
          className="dataEntryAddCopyDocument my-4"
          onClick={triggerExcelInput}
        >
          <div className="dataEntryAddInnerCopyDocument">
            <h6 className="data-entry-image-head">ADD SUMMARY DOCUMENT</h6>
          </div>
        </div>
        {selectedFile && (
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <span>{selectedFile.name}</span>
            <Button
              type="button"
              onClick={removeExcelFile}
              style={{ marginLeft: "10px" }}
            >
              X
            </Button>
          </div>
        )}
        {state && state?.summarydocument ? (
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <span>SBLC DOC.xlsx</span>
            <Button
              type="button"
              onClick={removeExcelFile}
              style={{ marginLeft: "10px" }}
            >
              X
            </Button>
          </div>
        ) : (
          <></>
        )}

        {[
          "SBLC DOCUMENT",
          "INVOICE DOCUMENT",
          "PHYTO DOCUMENT",
          "CO DOCUMENT",
          "BL DOCUMENT",
          "QUOTE DOCUMENT",
          "SWIFT INT DOCUMENT",
          "ADVICE DOCUMENT",
          "BOE DOCUMENT",
          "SHIPPING BILL DOCUMENT",
        ].map((name, index) => (
          <FileUploadContainer
            key={index}
            file={files[index]}
            onFileChange={(event) => handleFileChanges(index, event)}
            onRemove={() => handleFileRemove(index)}
            name={name}
            statepdf={state}
          />
        ))}
        <div className="dataEntrySubmitBlock">
          <button className="data-entry-submit-btn">SUBMIT</button>
        </div>
      </form>
      <PopUpModal isOpen={isPopupOpen} onClose={togglePopup}>
        <div className="img-block">
          <div style={{ width: "40%", height: "60%" }} className="mx-2 pt-2">
            <img src={logo} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
        <div className="d-flex flex-column align-items-center justify-content-center py-2">
          <h6 className="popup-content mb-3">
            Form Added updated successfully
          </h6>
          <button
            className="user-add-button mt-2 mb-2"
            onClick={() => {
              navigate("/");
            }}
          >
            OK
          </button>
        </div>
      </PopUpModal>
    </>
  );
};

export default DataEntryEditForm;
